import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import NativeSelect from "@material-ui/core/NativeSelect";

import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import { ChallanAPI } from "../../BL";
import { create, all } from "mathjs";
var moment = require("moment-timezone");
const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  boldInput: {
    textAlign: "right",
    fontSize: 18,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const today = moment().tz("Asia/Karachi").format("YYYY-MM-DD");

export default function ChallanEntryForm({

  handleClose,
  refreshEntryForm,
  order,
}) {
console.log(order)
  const intialState = {
    challan_client_name: order.order_customer_name,
    challan_number: "Loading...",
    challan_date1: today,
    challan_date2: order.order_date,
    challan_item_code: order.order_item_code,
    challan_vendor_code: order.order_vendor_code,
    challan_order_mumber: order.order_number,
    challan_qty: "",
    challan_rate:order.order_price,
    challan_qty_kg: order.order_qty_kg,
    challan_particullars: order.order_particullars,
    challanMsg: "",
    challan_to_emails: "nafeesenter@gmail.com",
    challan_send_email: false,
    challan_cust_id: order.order_customer_id,
    challan_id: order.challan_id === undefined ? 0 : order.challan_id,
    challan_hscode: order.order_hscode,
    challan_no_of_container: "",
    challan_qty_per_container: "",
    challan_total_qty_container: "",
    challan_no_of_container2: 0,
    challan_qty_per_container2: 0,
    challan_total_qty_container2: 0,
    challan_stock: "",
    challan_paper: "",
    challan_lot_number: "",
    challan_generated_by: "Test",
    challan_pdf_path: "",
    refreshEntryForm: refreshEntryForm,
    prevChallanDate: "",
    challan_order_id:order.order_id,
    challan_updated_by:"Test",
    challan_plastic_code:"",
    challan_plastic_kg:""
  };
  const config = {};
  const math = create(all, config);
  const classes = useStyles();
  // const [custs, setCusts] = useState([]);
  const obj = new ChallanAPI();
  const [values, setValues] = useState(intialState);
  const handleSubmit = (event) => {
    event.preventDefault();
    let _prevChallanDate = moment(values.prevChallanDate)
      .tz("Asia/Karachi")
      .format("YYYY-MM-DD");
    let _challan_date1 = moment(values.challan_date1)
      .tz("Asia/Karachi")
      .format("YYYY-MM-DD");

    if (new Date(_challan_date1) < new Date(_prevChallanDate)) {
    if(!window.confirm("Date must be equal or greater than previous challan date. Do you want to continue?")) {
      event.preventDefault();
      return false;
    }

    
    }

    values.challan_to_emails = values.challan_send_email
      ? values.challan_to_emails
      : "";

    setValues({
      ...values,
      challanMsg: values.challan_id != 0 ? "Updating..." : "Generating...",
    });

    obj.AddChallan(values)
      .then(function (response) {
        if (response.data != "Internal Error!") {
          setValues({ ...values, challanMsg: "Done!" });
          handleClose(true);
        } else {
          alert("Some thing is wrong! Please try again");
        }
      })
      .catch(function (error) {
        try {
          if (error.response.data.sqlMessage !== undefined) {
            setValues({
              ...values,
              challanMsg: error.response.data.sqlMessage,
            });
          } else {
            setValues({ ...values, challanMsg: "Server Error!" });
          }
        } catch (exx) {
          window.location.reload();
        }
      });
  };
  const handleSelectHSCode = (event) => {
    
    if (event.target.value !== -1) {
      setValues({
        ...values,
        
        challan_plastic_code: event.target.value,
      });
    }
  };

  const handleChangeChk = (name) => (event) => {
    // values[name]=event.target.value;
    // setState({ ...state, [name]: event.target.checked });
    setValues({ ...values, [name]: event.target.checked });
    //console.log(event.target.checked)
  };
  const calcQtyTotal = () => {
    let tot =
      values.challan_total_qty_container + values.challan_total_qty_container2;
    setValues({ ...values, challan_qty: tot });
  };
  const calcTotal = () => {
    let tot = values.challan_no_of_container * values.challan_qty_per_container;
    setValues({ ...values, challan_total_qty_container: tot });
  };
  const calcTotal2 = () => {
    let tot =
      values.challan_no_of_container2 * values.challan_qty_per_container2;
    setValues({ ...values, challan_total_qty_container2: tot });
  };

  const handleChange = (name) => (event) => {
  
    try {
      values[name] = event.target.value;

      setValues({ ...values, [name]: event.target.value });
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    if (values.challan_id != 0) {
      fetchchallanByID();
    } else {
      fetchNumber();
    }

    async function fetchNumber() {
      obj
        .challanNewNumber()
        .then((result) =>
          setValues({
            ...values,
            challan_number: result.challan_number + 1,
            prevChallanDate: result.challan_date1,
          })
        )
        .catch((err) => alert(err));
    }

    async function fetchchallanByID() {
      console.log("fetchchallanByID");
      obj.getChallanByID(values.challan_id).then((result) =>
        setValues({
          ...values,
          challan_id:result.challan_id,
          challan_qty:result.challan_qty,
          challan_qty_kg:result.challan_qty_kg,
          challan_number:result.challan_number,
          challan_date1:result.challan_date1.split("T")[0],
          challan_order_mumber:result.challan_order_mumber,
          challan_client_name:result.challan_client_name,
          challan_cust_id:result.challan_cust_id,
          challan_particullars:result.challan_particullars,
          challan_item_code:result.challan_item_code,
          challan_vendor_code:result.challan_vendor_code,
          challan_hscode:result.challan_hscode,
          challan_no_of_container:result.challan_no_of_container,
          challan_qty_per_container:result.challan_qty_per_container,
          challan_total_qty_container:
           result.challan_total_qty_container,
          challan_no_of_container2:result.challan_no_of_container2,
          challan_qty_per_container2:result.challan_qty_per_container2,
          challan_total_qty_container2:
           result.challan_total_qty_container2,
          challan_stock:result.challan_material_consumption,
          challan_paper:result.challan_paper_consumption,
          challan_lot_number:result.challan_lot_number,
          prevChallanDate:result.challan_date1.split("T")[0],
          challan_updated_by:'Test',
          challan_rate: result.challan_price,
          challan_plastic_code:result.challan_plastic_code,
          challan_plastic_kg:result.challan_plastic_kg
        })
      );
    }
  }, []);

  return (
    <React.Fragment>
      {/* 
        <Typography variant="h6" gutterBottom>
       challan
      </Typography>
     */}

      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              id="challan_number"
              name="challan_number"
              label="challan Number"
              fullWidth
              value={values.challan_number}
              onChange={handleChange("challan_number")}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              required
              label="Date1"
              fullWidth
              type="date"
              value={values.challan_date1}
              onChange={handleChange("challan_date1")}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              required
              id="challanLotNumber"
              name="challanLotNumber"
              label="Lot Number"
              fullWidth
              value={values.challan_lot_number}
              onChange={handleChange("challan_lot_number")}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              required
              label="No. of Container"
              fullWidth
              type="number"
              value={values.challan_no_of_container}
              onChange={handleChange("challan_no_of_container")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              type="number"
              label="Qty Per Container"
              fullWidth
              value={values.challan_qty_per_container}
              onChange={handleChange("challan_qty_per_container")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              type="number"
              label="Total Qty"
              fullWidth
              value={values.challan_total_qty_container}
              onChange={handleChange("challan_total_qty_container")}
            />
            <b onClick={calcTotal} style={{ cursor: "pointer" }}>
              ?
            </b>
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              required
              type="number"
              label="No. of Container"
              fullWidth
              value={values.challan_no_of_container2}
              onChange={handleChange("challan_no_of_container2")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              type="number"
              label="Qty Per Container"
              fullWidth
              value={values.challan_qty_per_container2}
              onChange={handleChange("challan_qty_per_container2")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              type="number"
              label="Total Qty"
              fullWidth
              value={values.challan_total_qty_container2}
              onChange={handleChange("challan_total_qty_container2")}
            />
            <b onClick={calcTotal2} style={{ cursor: "pointer" }}>
              ?
            </b>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              required
              type="number"
              label="Material Consumption"
              fullWidth
              value={values.challan_stock}
              onChange={handleChange("challan_stock")}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              required
              type="number"
              label="Paper Consumption"
              fullWidth
              value={values.challan_paper}
              onChange={handleChange("challan_paper")}
            />
          </Grid>

      

          <Grid item xs={12} sm={2}>
            <TextField
              required
              inputProps={{
                style: { textAlign: "right" },
              }}
              label="Pcs/KG"
              fullWidth
              type="number"
              value={values.challan_qty}
              onChange={handleChange("challan_qty")}
            />
            <b onClick={calcQtyTotal} style={{ cursor: "pointer" }}>
              ?
            </b>
          </Grid>
          <Grid item xs={12} sm={2

          }>
          <NativeSelect
                  value={values.challan_plastic_code}
                  required
                  onChange={handleSelectHSCode}
                >
                  <option value="-1">Plastic Code</option>
                  
                      <option value={3901}>
                        {3901}
                      </option>
                      <option value={3902} >
                        {3902}
                      </option>
                    
                </NativeSelect>
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              required
              inputProps={{
                style: { textAlign: "right" },
              }}
              label="KG"
              fullWidth
              type="number"
              value={values.challan_plastic_kg}
              onChange={handleChange("challan_plastic_kg")}
            />
            
          </Grid>
          {/* <Grid item xs={12} sm={2}>
            <TextField
              required
              type="number"
              inputProps={{
                style: { textAlign: "right" },
              }}
              label="Rate"
              fullWidth
              value={values.challan_rate}
              onChange={handleChange("challan_rate")}
            />
          </Grid> */}
          <Grid item xs={12}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              className={classes.button}
            >
              {values.challan_id != 0 ? "Update" : "Generate"}
            </Button>
            &nbsp;&nbsp;
            <FormLabel>{values.challanMsg}</FormLabel>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="challan_send_email"
                  checked={values.challan_send_email}
                />
              }
              label="Send Email?"
              onChange={handleChangeChk("challan_send_email")}
            />
            <TextField
              label="Email To Address(es):"
              fullWidth
              value={values.challan_send_email ? values.challan_to_emails : ""}
              onChange={handleChange("challan_to_emails")}
            />
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}
